@media screen and (min-width: 300px), print {
    .content-width,
    .content-width-narrow {
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media screen and (min-width: 400px), print {
    .content-width,
    .content-width-narrow {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media screen and (min-width: 1300px) {
    .content-width {
        padding-left: calc(50% - 650px);
        padding-right: calc(50% - 650px);
        max-width: '1300px';
    }
}

@media screen and (min-width: 1000px) {
    .content-width-narrow {
        padding-left: calc(50% - 500px);
        padding-right: calc(50% - 500px);
        max-width: '1000px';
    }
}

body {
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

img[src*='#center'] {
    display: block;
    margin: auto;
}
